import { useApplication } from '@common/application';
import { getCombinedLinkFieldValueHref } from '@common/link';
import RichText from '@components/RichText/RichText';
import { PayOff } from '@custom-components/weDoenHetNuCampaign/PayOff';
import { SpeechBubble, SpeechBubbleBackground } from '@custom-components/weDoenHetNuCampaign/SpeechBubble';
import {
  AnimationWrapper,
  BackDrop,
  SpeechBubbleWrapper,
  StyledText,
  StyledTextLink,
  TestimonialsCardBackground,
} from '@custom-components/weDoenHetNuCampaign/TestimonialsCard';
import { CardSpeechBubbleHeading, PayOffWrapper } from '@custom-components/weDoenHetNuCampaign/WeDoenHetNuCard';
import { Link } from '@dxp-next';
import { wrap } from '@sitecore/common';
import { WeDoenHetNuTestimonialsCardRendering } from '@sitecore/types/manual/WeDoenHetNuTestimonialsCard';
import { Box, Grid, Stack, Text, TextLink } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';

import Image from '../Image/Image';

const TestimonialsCard: React.FC<WeDoenHetNuTestimonialsCardRendering> = ({
  params: { highlightTitleOption = 'second', color = 'weDoenHetNuVarTwo' } = {},
  fields,
}) => {
  const isDesktop = useMediaQuery('md');
  const hasSoftHyphen =
    fields?.titleContent?.value.includes('&shy;') || fields?.subTitleContent?.value.includes('&shy;');
  const { language } = useApplication();
  if (fields.cardLink) {
    return (
      <StyledTextLink
        aria-label={fields.cardLink.value.text}
        backgroundColor={color}
        href={getCombinedLinkFieldValueHref(fields.cardLink.value)}>
        <BackDrop />
        <TestimonialsCardComponent fields={fields}>
          <Stack alignX="center">
            <AnimationWrapper>
              <Stack gap="12">
                <SpeechBubble backgroundColor={color}>
                  <CardSpeechBubbleHeading
                    size={isDesktop ? 'headingL' : 'headingM'}
                    hyphens={hasSoftHyphen ? 'manual' : 'auto'}
                    as="h2">
                    <StyledText color={highlightTitleOption === 'first' && color ? color : undefined}>
                      {/* TODO remove title field when titleContent is being used on all cards */}
                      {wrap(fields?.title)}
                      <RichText html={fields?.titleContent?.value} />
                    </StyledText>
                    <StyledText color={highlightTitleOption === 'second' && color ? color : undefined}>
                      {/* TODO remove subTitle field when subTitleContent is being used on all cards */}
                      {wrap(fields?.subTitle)}
                      <RichText html={fields?.subTitleContent?.value} />
                    </StyledText>
                  </CardSpeechBubbleHeading>
                </SpeechBubble>
                <Box paddingTop="3">
                  <Stack alignY="end">
                    <PayOffWrapper>
                      <PayOff backgroundColor={color} language={language} />
                    </PayOffWrapper>
                  </Stack>
                </Box>
              </Stack>
            </AnimationWrapper>
          </Stack>
        </TestimonialsCardComponent>
      </StyledTextLink>
    );
  }
  if (fields.link && fields.content) {
    return (
      <Stack gap="6" alignY="justify">
        <Stack gap="6">
          <TestimonialsCardBackground backgroundColor={color}>
            <TestimonialsCardComponent fields={fields}>
              <Stack alignX="center">
                <SpeechBubbleWrapper>
                  <Box paddingX="6">
                    <SpeechBubbleBackground backgroundColor={color}>
                      <CardSpeechBubbleHeading as="h2" size="headingM" hyphens={hasSoftHyphen ? 'manual' : 'auto'}>
                        <StyledText color={highlightTitleOption === 'first' && color ? color : undefined}>
                          {/* TODO remove title field when titleContent is being used on all cards */}
                          {wrap(fields?.title)}
                          <RichText html={fields?.titleContent?.value} />
                        </StyledText>
                        <StyledText color={highlightTitleOption === 'second' && color ? color : undefined}>
                          {/* TODO remove subTitle field when subTitleContent is being used on all cards */}
                          {wrap(fields?.subTitle)}
                          <RichText html={fields?.subTitleContent?.value} />
                        </StyledText>
                      </CardSpeechBubbleHeading>
                    </SpeechBubbleBackground>
                  </Box>
                </SpeechBubbleWrapper>
              </Stack>
            </TestimonialsCardComponent>
          </TestimonialsCardBackground>
          <Text size={{ initial: 'BodyM', md: 'BodyL' }}>
            {wrap(fields?.content, <RichText html={fields?.content?.value} />)}
          </Text>
        </Stack>
        <Link editable={fields.link.editable} linkType={fields.link.value.linktype} linkValue={fields.link.value}>
          <TextLink emphasis="high">{fields.link.value.text}</TextLink>
        </Link>
      </Stack>
    );
  }
  return null;
};

const TestimonialsCardComponent: React.FC<
  React.PropsWithChildren<Pick<WeDoenHetNuTestimonialsCardRendering, 'fields'>>
> = ({ fields, children }) => {
  return (
    <Grid columns="1" gridTemplateRows="3fr 1fr">
      <Grid.Item gridRow="1">
        <Image componentName="WeDoenHetNuTestimonialsCard" fields={fields} params={{ objectFit: 'cover' }} />
      </Grid.Item>
      <Grid.Item gridRow="2">{children}</Grid.Item>
    </Grid>
  );
};

export default TestimonialsCard;
