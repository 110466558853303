import { Box, Text } from '@sparky';
import { styled } from '@sparky/stitches';

import { campaignBackgroundColors, campaignTextColors } from './campaignTokens';

export const BackDrop = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',

  '@safeMotion': {
    transition: '$easeMedium',
  },
});

export const AnimationWrapper = styled('div', {
  position: 'absolute',
  bottom: '-85px',
  paddingX: '$6',

  '@safeMotion': {
    transition: '$easeMedium',
  },
});

export const StyledTextLink = styled('a', {
  position: 'relative',
  display: 'inline-block',
  overflow: 'hidden',
  variants: {
    backgroundColor: campaignBackgroundColors,
  },

  '&:hover': {
    [`& ${AnimationWrapper}`]: {
      transform: 'translateY(-110px)',
    },
    [`& ${BackDrop}`]: {
      backgroundColor: 'inherit',
      opacity: 0.5,
    },
  },
});

export const StyledText = styled(Text, {
  color: '$textInverted',
  variants: {
    color: campaignTextColors,
  },
});

export const TestimonialsCardBackground = styled(Box, {
  position: 'relative',
  display: 'inline-block',
  overflow: 'hidden',
  variants: {
    backgroundColor: campaignBackgroundColors,
  },
});

export const SpeechBubbleWrapper = styled('div', {
  position: 'absolute',
  bottom: 0,
});
